import React, { useEffect, useState } from "react";
import Header from "../component/header";
import Card from "../component/card";
import "../css/main.css";
import "../css/component.css";
import { RetriveAccessToken, RetriveToken } from "../../utility/const";
import { useNavigate } from "react-router-dom";
import { url } from "../../utility/apis";
import { useSelector } from "react-redux";
const Dashboard = () => {
  const navigate = useNavigate();
  // const { token } = useSelector((state) => state.token);
  const token = RetriveAccessToken("access");
  const [count, setCount] = useState({});

  const FetchCounts = async () => {
    const response = await fetch(url.countApis, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsondata = await response.json();
      setCount(jsondata?.data);
    }
  };
  useEffect(() => {
    FetchCounts();
  }, []);

  return (
    <div className="mainHead">
      <Header />
      <div className="dashContainer">
        <div className="container">
          <p id="head">Dashboard</p>
          <div className="cards">
            <Card
              title={"Total Organization"}
              total={count?.organization}
              onClick={() => navigate("/org")}
            />
            <Card
              title={"Total Appointments"}
              total={count?.appointment}
              onClick={() => navigate("/app")}
            />
            <Card
              title={"Total Doctors"}
              total={count?.doctors}
              onClick={() => navigate("/doc")}
            />
            <Card
              title={"Total Patients"}
              total={count?.patients}
              onClick={() => navigate("/pat")}
            />
            <Card
              title={"Total Users"}
              total={count?.users_count}
              onClick={() => navigate("/register-users")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
