import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import "../css/main.css";
import {
  RetriveToken,
  StoreAccessToken,
  StoreToken,
  notify,
} from "../../utility/const";
import { json, useLocation, useNavigate } from "react-router-dom";
import Button from "../component/button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { url } from "../../utility/apis";
import { useDispatch } from "react-redux";
import { addToken } from "../../redux/features";
const Login = (route) => {
  const dispatch = useDispatch();
  const [logotNotify, setLogotNotify] = useState(false);
  const location = useLocation();
  const page = location.state?.page;
  const navigate = useNavigate();
  const [phone, setphone] = useState("");
  const [password, setpassword] = useState("");
  const handlePhone = (event) => {
    setphone(event.target.value);
  };
  const handlePassword = (event) => {
    setpassword(event.target.value);
  };
  const PostData = async () => {
    try {
      const URL = url.login;
      const respense = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: phone,
          password: password,
        }),
      });
      if (respense.ok) {
        const jsondata = await respense.json();
        StoreToken("login", {
          refresh_token: jsondata?.data?.refresh_token,
        });
        // dispatch(addToken(jsondata?.data?.access_token));
        StoreAccessToken("access", jsondata?.data?.access_token);
        if (jsondata?.status === "success") {
          navigate("/dash");
        } else {
          notify("Invalid credentials", "error");
        }
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };
  const fetchAccess_token = async () => {
    const refresh_token = RetriveToken("login");
    try {
      const URL = url.getNewAccessToken;
      const respense = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(refresh_token),
      });
      const jsondata = await respense.json();
      if (respense.ok) {
        StoreAccessToken("access", jsondata?.access_token);
        if (jsondata) {
          navigate("/dash");
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };
  useEffect(() => {
    fetchAccess_token();
  }, []);
  useEffect(() => {
    if (page && !logotNotify) {
      notify("Logout Successfully", "success");
      setLogotNotify(true);
    }
  }, [logotNotify]);

  return (
    <div className="loginContainer">
      <div className="loginSubContainer">
        <img src={logo} alt="logo" className="Logo" />
        <div className="login">
          <input
            placeholder="Phone number"
            className="Input"
            value={phone}
            onChange={handlePhone}
            maxLength={10}
          />
          <input
            type="password"
            placeholder="Password"
            className="Input"
            value={password}
            onChange={handlePassword}
          />
        </div>
        <Button
          className="loginbtn"
          onClick={() => {
            PostData();
            setphone("");
            setpassword("");
          }}
          text={"Login"}
        ></Button>
        <ToastContainer />
      </div>
    </div>
  );
};
export default Login;
