import React, { useEffect, useState } from "react";
import Header from "../component/header";
import "../css/main.css";
import Button from "../component/button";
import CalendarData from "../component/calendar";
import Radio from "../component/radio";
import DataTable from "react-data-table-component";
import {
  RetriveAccessToken,
  RetriveToken,
  filterdatabyDate,
  isDesktopOrLaptop,
  isSmallMobile,
  ismobile,
  istab,
} from "../../utility/const";
import { url } from "../../utility/apis";
import { DropDown } from "../component/dropdown";

const Doctor = () => {
  const token = RetriveAccessToken("access");
  const [docdata, setData] = useState();
  const [count, setCount] = useState(1);
  const [page, setpage] = useState(1);
  const [orgdata, setOrgData] = useState({});
  const [option, setOptions] = useState([]);
  const [filtered, setFiltered] = useState("Today");
  const [from, setFrom] = useState(new Date()?.toISOString()?.split("T")[0]);
  const [to, setTo] = useState(new Date()?.toISOString()?.split("T")[0]);
  const currentDate = new Date();
  const oneYearAgo = new Date(currentDate);
  oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
  const oneMonthAgo = new Date(currentDate);
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(currentDate.getDate() - 7);
  const handleFilter = (event) => {
    setFiltered(event);
    if (event === "Today") {
      //  setpage(1)
      setFrom(currentDate?.toISOString()?.split("T")[0]);
      setTo(currentDate?.toISOString()?.split("T")[0]);
    } else if (event === "Week") {
      //   setpage(1)
      setFrom(oneWeekAgo?.toISOString()?.split("T")[0]);
      setTo(currentDate?.toISOString()?.split("T")[0]);
    } else if (event === "Month") {
      //   setpage(1)
      setFrom(oneMonthAgo?.toISOString()?.split("T")[0]);
      setTo(currentDate?.toISOString()?.split("T")[0]);
    } else if (event === "Year") {
      //   setpage(1)
      setFrom(oneYearAgo?.toISOString()?.split("T")[0]);
      setTo(currentDate?.toISOString()?.split("T")[0]);
    } else {
    }
    setpage(1);
  };

  const HandleFromdate = (event) => {
    setFrom(event.target.value);
    setpage(1);
    //  setFiltered(event.target.value == "")
  };
  const HandleTodate = (event) => {
    setTo(event.target.value);
    setpage(1);
    // setFiltered('')
  };
  const FetchDoctor = async () => {
    const response = await fetch(
      url.getdoctor(
        page,
        from,
        to,
        orgdata?.profile_id ? orgdata?.profile_id : ""
      ),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.ok) {
      const jsondata = await response.json();
      setData(jsondata?.data);
    }
  };

  const Fetch_Org_Metadata = async () => {
    const response = await fetch(url?.getOrganizationMetadata, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsondata = await response.json();
      const newdata = jsondata?.data?.map((item) => ({
        name: item?.organization?.name,
        profile_id: item?.profile_id,
      }));
      setOptions(newdata);
    }
  };
  useEffect(() => {
    Fetch_Org_Metadata();
  }, []);
  useEffect(() => {
    FetchDoctor();
  }, [page, from, to, orgdata]);
  const filter = [
    {
      value: "Today",
      label: "Today",
    },
    { value: "Week", label: "Week" },
    {
      value: "Month",
      label: "Month",
    },
    { value: "Year", label: "Year" },
  ];
  const customStyles = {
    rows: {
      style: {
        color: "#000000",
      },
    },

    headCells: {
      style: {
        backgroundColor: "#fff",
        borderColor: "#4ba5fa",
        color: "black",
        fontSize: 14,
        fontWeight: "600",
      },
    },
    cells: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "0.5px",
        borderTopColor: "#4ba5fa",
      },
    },
    pagination: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "0.5px",
        borderTopColor: "#4ba5fa",
      },
    },
  };
  const columns = [
    {
      name: "S.No",
      selector: (row) => row.sno,
      width: "64px",
    },
    {
      name: "Dr Name",
      selector: (row) => row.drname,
    },
    {
      name: "Dr Phone",
      selector: (row) => row.drph,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      width: "84px",
    },
    {
      name: "Specialization",
      selector: (row) => row.special,
      width: "180px",
    },
    {
      name: "State",
      selector: (row) => row.state,
    },
    {
      name: "Created at",
      selector: (row) => row.created,
    },
  ];
  const data = docdata?.data?.map((item, index) => {
    return {
      sno: count + index,
      drname:
        item?.profile?.practitioner?.name?.text?.toLowerCase()?.startsWith("dr") ||
          item?.profile?.practitioner?.name?.text?.toLowerCase()?.startsWith("dr.")
          ? item?.profile?.practitioner?.name?.text?.toUpperCase()
          : `Dr. ${item?.profile?.practitioner?.name?.text?.toUpperCase()}`,
      drph: item?.profile?.practitioner?.telecom[0]?.value,
      gender: item?.profile?.practitioner?.gender,
      special: [
        ...new Set(
          item?.role?.map((ele) =>
            ele?.role?.specialty[0]?.coding?.map((val) => val?.display)?.join(",")
          )
        ),
      ]?.join(","),
      state: item?.profile?.practitioner?.address[0]?.state,
      created: item?.profile?.created_at?.split("T")[0],
    };
  });
  return (
    <div className="mainHead">
      <Header />
      <div className="dashContainer">
        <div className="contain">
          <p id="head">Doctors</p>
          {(istab || ismobile || isSmallMobile) && (
            <Button
              style={{ width: 200, fontWeight: 600 }}
              text={`Count : ${docdata?.count ? docdata?.count : 0}`}
            />
          )}
          <div className="top">
            {isDesktopOrLaptop && (
              <Button
                style={{ width: 200, fontWeight: 600 }}
                text={`Count : ${docdata?.count ? docdata?.count : 0}`}
              />
            )}
            <DropDown
              options={option}
              pr_name={"name"}
              value={orgdata}
              setValue={setOrgData}
              label={"Select Organization"}
            />
            <div className="second">
              <p id="subtext">Filter by</p>
              <div className="third">
                <CalendarData value={from} setvalue={HandleFromdate} />
                <CalendarData value={to} setvalue={HandleTodate} />
              </div>
              <div className="radio">
                {filter?.map((item) => (
                  <Radio
                    key={item}
                    value={filtered ? filtered : "Today"}
                    label={item?.label}
                    setvalue={() => handleFilter(item?.value)}
                    checked={filtered === item?.value}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="table">
            <DataTable
              columns={columns}
              data={data}
              // pagination
              customStyles={customStyles}
            />
          </div>
          <div className="bottomNav">
            {page > 1 && (
              <Button
                style={{ width: 72, padding: 4, fontSize: 14 }}
                left={true}
                text={"Prev"}
                onClick={() => {
                  setpage(page - 1);
                  setCount(count - 15);
                }}
              ></Button>
            )}
            {data?.length >= 10 && (
              <Button
                style={{ width: 72, padding: 4, fontSize: 14 }}
                text={"Next"}
                onClick={() => {
                  setpage(page + parseInt(1));
                  setCount(count + 15);
                }}
                right={true}
              ></Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Doctor;
