import React, { useEffect, useState } from "react";
import Header from "../component/header";
import Card from "../component/card";
import "../css/main.css";
import Button from "../component/button";
import CalendarData from "../component/calendar";
import Radio from "../component/radio";
import DataTable from "react-data-table-component";
import {
    RetriveAccessToken,
    RetriveToken,
    filterdatabyDate,
} from "../../utility/const";
import { url } from "../../utility/apis";
import moment from "moment/moment";

const RegisterUsers = () => {
    const roles = ["Admin", "Doctor", "User", "Patient"]
    const [role, setRole] = useState("")
    const token = RetriveAccessToken("access");
    const [data, setData] = useState({ count: 0, data: [] });
    const [page, setpage] = useState(1);
    const [organizations, setOrganizations] = useState([])
    const [count, setCount] = useState(1);
    const [filtered, setFiltered] = useState("Today");
    const [from, setFrom] = useState(new Date()?.toISOString()?.split("T")[0]);
    const [to, setTo] = useState(new Date()?.toISOString()?.split("T")[0]);
    const currentDate = new Date();
    const oneYearAgo = new Date(currentDate);
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
    const oneMonthAgo = new Date(currentDate);
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);
    const oneWeekAgo = new Date(currentDate);
    oneWeekAgo.setDate(currentDate.getDate() - 7);
    const handleFilter = (event) => {
        setFiltered(event);
        if (event === "Today") {
            setpage(1);
            setFrom(currentDate?.toISOString()?.split("T")[0]);
            setTo(currentDate?.toISOString()?.split("T")[0]);
        } else if (event === "Week") {
            setpage(1);
            setFrom(oneWeekAgo?.toISOString()?.split("T")[0]);
            setTo(currentDate?.toISOString()?.split("T")[0]);
        } else if (event === "Month") {
            setpage(1);
            setFrom(oneMonthAgo?.toISOString()?.split("T")[0]);
            setTo(currentDate?.toISOString()?.split("T")[0]);
        } else if (event === "Year") {
            setpage(1);
            setFrom(oneYearAgo?.toISOString()?.split("T")[0]);
            setTo(currentDate?.toISOString()?.split("T")[0]);
        } else {
        }
    };

    const HandleFromdate = (event) => {
        setFrom(event.target.value);
        setpage(1);
        //  setFiltered(event.target.value == "")
    };
    const HandleTodate = (event) => {
        setTo(event.target.value);
        setpage(1);
        // setFiltered('')
    };
    const FetchRegisterUsers = async () => {
        const response = await fetch(url.getRegisteredUsers(page, from, to, role), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            const jsondata = await response.json();
            setData(jsondata?.data);
        }
    };
    useEffect(() => {
        FetchRegisterUsers();
    }, [page, from, to, role]);
    // useEffect(() => {
    //     const fetchOrganizations = async () => {
    //         const response = await fetch(url.getAllOrganizationa, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             }
    //         })
    //         const data = await response.json()
    //         const org_data = await data?.data?.length > 0 ? data?.data?.map(item => ({ name: item?.organization?.name, profile_id: item?.profile_id })) : []
    //         setOrganizations(org_data)
    //         console.log(org_data);
    //     }
    //     fetchOrganizations()
    // }, [])
    const filter = [
        {
            value: "Today",
            label: "Today",
        },
        { value: "Week", label: "Week" },
        {
            value: "Month",
            label: "Month",
        },
        { value: "Year", label: "Year" },
    ];
    const customStyles = {
        rows: {
            style: {
                color: "#000000",
            },
        },

        headCells: {
            style: {
                backgroundColor: "#fff",
                borderColor: "#4ba5fa",
                color: "black",
                fontSize: 14,
                fontWeight: "600",
            },
        },
        cells: {
            style: {
                borderTopStyle: "solid",
                borderTopWidth: "0.5px",
                borderTopColor: "#4ba5fa",
            },
        },
        pagination: {
            style: {
                borderTopStyle: "solid",
                borderTopWidth: "0.5px",
                borderTopColor: "#4ba5fa",
            },
        },
    };
    const columns = [
        {
            name: "S.No",
            selector: (row) => row.sno,
            width: "64px",
        },
        {
            name: "Phone Number",
            selector: (row) => row.phonenumber,
            width: "180px",
        },
        {
            name: "Role",
            selector: (row) => row.role,
        },
        {
            name: "Profile ID",
            selector: (row) => row.profile_id,
        },
        {
            name: "Created At",
            selector: (row) => row.created_at,
        },
        {
            name: "IsActive",
            selector: (row) => row.isActive,
        },
        {
            name: "Access Control",
            selector: (row) => row.access,
        },
    ];
    const updateAccess = async (id, status) => {
        const response = await fetch(url.updateUserAccess(id), {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ status })
        })
        const jsonData = await response.json()
        if (jsonData?.status === "success") {
            FetchRegisterUsers()
        }
    }
    const user_data = data?.data?.map((item, index) => {
        const sno = (page - 1) * 15 + index + 1
        const phonenumber = item?.phoneNumber
        const role = item?.role?.join(", ")
        const profile_id = item?.profile_id
        const created_at = moment(item?.created_at).format('DD-MM-YYYY')
        const isActive = item?.isActive ? "Active" : "Not Active"
        const access = <button style={{
            border: item?.isActive ? '1px solid #4ba5fa' : 'none',
            padding: 4, borderRadius: 8, width: '8em',
            backgroundColor: item?.isActive ? '#fff' : '#4ba5fa',
            color: item?.isActive ? '#4ba5fa' : '#fff'
        }}
            onClick={async () => await updateAccess(item?._id, item?.isActive ? false : true)}
        >
            {item?.isActive ? "DeActivate" : "Activate"}
        </button>
        return { sno, phonenumber, role, profile_id, created_at, isActive, access }
    })
    return (
        <div className="mainHead">
            <Header />
            <div className="dashContainer">
                <div className="contain">
                    <p id="head">Registered Users</p>
                    <div className="top">
                        <Button
                            style={{ width: 200, fontWeight: 600 }}
                            text={` Count : ${data?.count}`}
                        />
                        <div className="radio">
                            {roles.map((item) => (
                                <Button key={item} text={item} onClick={() => { role.toLowerCase() === item?.toLowerCase() ? setRole("") : setRole(item?.toLowerCase()) }} style={{ backgroundColor: item?.toLowerCase() === role?.toLowerCase() ? "#4ba5fa" : "#fff", color: item?.toLowerCase() === role?.toLowerCase() ? "#fff" : "#4ba5fa" }} />
                            ))}
                        </div>
                        <div className="second">
                            <p id="subtext">Filter by</p>
                            <div className="third">
                                <CalendarData value={from} setvalue={HandleFromdate} />
                                <CalendarData value={to} setvalue={HandleTodate} />
                            </div>
                            <div className="radio">
                                {filter?.map((item) => (
                                    <Radio
                                        key={item}
                                        value={filtered ? filtered : "Today"}
                                        label={item?.label}
                                        setvalue={() => handleFilter(item?.value)}
                                        checked={filtered === item?.value}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="table">
                        <DataTable
                            columns={columns}
                            data={user_data}
                            // pagination
                            customStyles={customStyles}
                        />
                    </div>
                    <div className="bottomNav">
                        {page > 1 && (
                            <Button
                                style={{ width: 72, padding: 4, fontSize: 14 }}
                                left={true}
                                text={"Prev"}
                                onClick={() => {
                                    setpage(page - 1);
                                    setCount(count - 15);
                                }}
                            ></Button>
                        )}
                        {data?.length >= 15 && (
                            <Button
                                style={{ width: 72, padding: 4, fontSize: 14 }}
                                text={"Next"}
                                onClick={() => {
                                    setpage(page + parseInt(1));
                                    setCount(count + 15);
                                }}
                                right={true}
                            ></Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default RegisterUsers;
