import React, { useState } from "react";
import logo from "../../assets/logo.png";
import user from "../../assets/user.png";
import "../css/main.css";
import { url } from "../../utility/apis";
import {
  RemoveToken,
  RetriveAccessToken,
  RetriveToken,
} from "../../utility/const";
import { useNavigate } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { LuCalendarClock } from "react-icons/lu";
import { FaUserDoctor } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import { FaAngleLeft } from "react-icons/fa6";

const Header = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("nav_menu");
  const [logoimg, setLogo] = useState(false);
  const [navIcons, setNavIcons] = useState(false);
  const HandleActive = () => {
    active === "nav_menu"
      ? setActive("nav_menu nav_active")
      : setActive("nav_menu");
    setNavIcons(!navIcons);
  };
  const refresh_token = RetriveToken("login");
  const token = RetriveAccessToken("access");
  const Logout = async () => {
    try {
      const response = await fetch(url.logout, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(refresh_token),
      });

      if (response.ok) {
        const jsonData = await response.json();
        RemoveToken("login");
        navigate("/", { state: { page: "1" } });
      } else {
        console.log("Logout failed. Response:", response);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  return (
    <nav className="nav">
      <div className="nav_toggler" onClick={HandleActive}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      <img src={logo} alt="logo" className="headerlogo nav_logo" />
      <div className="gap">
        <ul className={active}>
          {navIcons && (
            <li className="nav_arrow" onClick={HandleActive}>
              <FaAngleLeft size={20} color="#797B7E" />
            </li>
          )}
          <img src={logo} alt="logo" className="headerlogo logo_active" />
          <li className="nav_item">
            <a href="/dash" className="nav_link">
              {navIcons && <MdDashboard className="nav_icons" />} Dashboard
            </a>
          </li>
          <li className="nav_item">
            <a href="/org" className="nav_link">
              {navIcons && <IoIosLogOut className="nav_icons" />} Organizations
            </a>
          </li>
          <li className="nav_item">
            <a href="/app" className="nav_link">
              {navIcons && <LuCalendarClock className="nav_icons" />}{" "}
              Appointments
            </a>
          </li>
          <li className="nav_item">
            <a href="/doc" className="nav_link">
              {navIcons && <FaUserDoctor className="nav_icons" />} Doctors
            </a>
          </li>
          <li className="nav_item">
            <a href="/pat" className="nav_link">
              {navIcons && <FaUser className="nav_icons" />} Patients
            </a>
          </li>
          <li className="nav_item">
            <a href="/reviews" className="nav_link">
              {navIcons && <FaUser className="nav_icons" />} Reviews
            </a>
          </li>
          <li className="nav_item">
            <a onClick={Logout} className="nav_link">
              {navIcons && <IoIosLogOut className="nav_icons" />} Logout
            </a>
          </li>
        </ul>
        <div className="user">
          <img src={user} className="profile" alt="user profile" />
          <p className="username">Admin</p>
        </div>
      </div>
    </nav>
  );
};

export default Header;
{
  /* <div className="headerContainer">
<label className="barbtn">
<i class="fa-solid fa-bars"></i>
</label>
<img src={logo} alt="logo" className="headerlogo" />
<nav>
 <a href="/dash">Dashboard</a>
 <a href="/app">Appointments</a>
 <a href="/doc">Doctors</a>
 <a href="/pat">Patients</a>
 <a className="logout" onClick={Logout}>Logout</a>
 
</nav>
<div className="user">
   <img src={user} className="profile" alt="user profile" />
   <p className="username">Admin</p>
 </div>

</div> */
}
