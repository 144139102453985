import React, { useEffect } from "react";
import Login from "./root/screen/login";
import Dashboard from "./root/screen/dashboard";
import Appointments from "./root/screen/appointment";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Doctor from "./root/screen/doctor";
import Patient from "./root/screen/patient";
import Header from "./root/component/header";
import { RetriveToken } from "./utility/const";
import { app, requestForToken } from "./firebase";
import { getMessaging, onMessage } from "@firebase/messaging";
import { Organization } from "./root/screen/organizations";
import Reviews from "./root/screen/Review";
import RegisterUsers from "./root/screen/users";

const App = () => {
  // const retriveToken = async () => {
  //   try {
  //     const token = await requestForToken();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // useEffect(() => {
  //   retriveToken();
  // }, []);

  // const messaging = getMessaging(app);
  // onMessage(messaging, (payload) => { });

  // const HandleReload = () => {
  //   window.location.reload();
  //   history.push("/");
  // };
  // useEffect(() => {
  //   HandleReload();
  // }, []);

  return (
    <div>
      <Router>
        {/* {(header?.header !== undefined || header?.header !== null) &&
        header?.header ? (
          <Header />
        ) : null} */}
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="/dash" element={<Dashboard />} />
          <Route path="/app" element={<Appointments />} />
          <Route path="/doc" element={<Doctor />} />
          <Route path="/pat" element={<Patient />} />
          <Route path="/org" element={<Organization />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/register-users" element={<RegisterUsers />} />
        </Routes>
      </Router>
    </div>
  );
};
export default App;
