import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const StoreToken = (key, body) => {
  localStorage.setItem(key, JSON.stringify(body));
  return;
};

export const RetriveToken = (key) => {
  const token = localStorage.getItem(key);
  return JSON.parse(token);
};

export const RemoveToken = (key) => {
  localStorage.removeItem(key);
  return;
};

export const filterdatabyDate = (data, from, to) => {
  if (!from && !to) {
    return data;
  }
  const filteredDate = data?.filter((item) => {
    const created = item?.created;
    return (!from || created >= from) && (!to || created <= to);
  });
  return filteredDate;
};

export const filterAppointment = (data, from, to) => {
  if (!from && !to) {
    return data;
  }

  const filteredDate = data?.filter((item) => {
    const created = item?.date;
    return (!from || created >= from) && (!to || created <= to);
  });
  return filteredDate;
};

export const capitalizeWord = (word) => {
  if (word?.length === 0) return word;
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};
export const UppercaseWord = (word) => {
  if (word?.length === 0) return word;
  return word.toUpperCase();
};

export const isDesktopOrLaptop = window?.innerWidth >= 1280;
export const istab = window?.innerWidth <= 1279 && window?.innerWidth >= 680;
export const ismobile = window?.innerWidth <= 679 && window?.innerWidth >= 375;
export const isSmallMobile = window?.innerWidth < 375;

export const notify = (message, type) =>
  toast(message, {
    type: type,
  });

export const StoreAccessToken = (key, body) => {
  sessionStorage.setItem(key, JSON.stringify(body));
  return null;
};
export const RetriveAccessToken = (key) => {
  const token = sessionStorage.getItem(key);
  return JSON.parse(token);
};
