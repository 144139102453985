import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { UppercaseWord, capitalizeWord } from "../../utility/const";

export const DropDown = ({
  value = "",
  setValue,
  options = [],
  pr_name,
  sx,
  required = false,
  size,
  label,
  labelId,
  id,
}) => {
  return (
    <FormControl
      className="w-full"
      size={size ? size : "medium"}
      fullWidth
      sx={{ zIndex: 0 }}
    >
      <InputLabel id={id ? id : "select"} sx={{ fontFamily: "Open sans" }}>
        {label}
      </InputLabel>
      <Select
        required={required}
        labelId={labelId ? labelId : "select-label"}
        id={id ? id : "select"}
        label={label}
        value={pr_name ? value[pr_name] : value ? value : ""}
        onChange={(e) => {
          setValue(pr_name ? JSON.parse(e.target.value) : e.target.value);
        }}
        sx={sx}
      >
        {options?.map((item, ind) => (
          <MenuItem key={ind} value={pr_name ? JSON.stringify(item) : item}>
            {pr_name ? UppercaseWord(item[pr_name]) : UppercaseWord(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
