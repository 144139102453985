// const baseurl = "https://attai.healthattai.com/api/v1/";
// const baseurl = 'http://127.0.0.1:8000/api/v1';
const baseurl = "http://13.233.158.224/api/v1";

export const url = {
  login: `${baseurl}/login`,
  getNewAccessToken: `${baseurl}/get_new_access_token`,
  countApis: `${baseurl}/admin_dashboard`,
  getOrganizationMetadata: `${baseurl}/admin_dashboard/organizations_meta_data`,
  getDoctorsMetadata: (org_id) =>
    `${baseurl}/admin_dashboard/doctors_meta_data?org_id=${org_id}`,
  getPatientsMetadata: `${baseurl}/admin_dashboard/patients_meta_data`,
  getpatient: (page = 1, start_date, end_date) =>
    `${baseurl}/admin_dashboard/patient/${page}?start_date=${start_date}&&end_date=${end_date}`,
  getOrganizations: (page = 1, start_date, end_date) =>
    `${baseurl}/admin_dashboard/organizations/${page}?start_date=${start_date}&&end_date=${end_date}`,
  // practitioner_id: str = Query(None), organization_id: str = Query(None), patient_id: str = Query(None)
  getappointment: (
    page,
    from,
    to,
    practitioner_id,
    organization_id,
    patient_id
  ) =>
    `${baseurl}/admin_dashboard/appointments/${page}?start_date=${from}&&end_date=${to}&&practitioner_id=${practitioner_id}&&organization_id=${organization_id}&&patient_id=${patient_id}`,
  getdoctor: (page, from, to, org_id) =>
    `${baseurl}/admin_dashboard/doctors/${page}?start_date=${from}&&end_date=${to}&&org_id=${org_id}`,
  logout: `${baseurl}/logout`,
  getReviews: (page) => `${baseurl}/getReviews?page=${page}`,
  getRegisteredUsers: (page, start_date, end_date, role) =>
    `${baseurl}/registered_users/${page}?start_date=${start_date}&&end_date=${end_date}&&role=${role}`,
  getAllOrganizationa: `${baseurl}/admin_dashboard/organizations`,
  updateUserAccess: (id) => `${baseurl}/updateAcess/${id}`,
};
