import React, { useEffect, useState } from "react";
import Header from "../component/header";
import Card from "../component/card";
import "../css/main.css";
import Button from "../component/button";
import CalendarData from "../component/calendar";
import Radio from "../component/radio";
import DataTable from "react-data-table-component";
import {
  RetriveAccessToken,
  RetriveToken,
  filterdatabyDate,
} from "../../utility/const";
import { url } from "../../utility/apis";

export const Organization = () => {
  const token = RetriveAccessToken("access");
  const [organization, setData] = useState({ count: 0, patient_data: [] });
  const [page, setpage] = useState(1);
  const [count, setCount] = useState(1);
  const [filtered, setFiltered] = useState("Today");
  const [from, setFrom] = useState(new Date()?.toISOString()?.split("T")[0]);
  const [to, setTo] = useState(new Date()?.toISOString()?.split("T")[0]);
  const currentDate = new Date();
  const oneYearAgo = new Date(currentDate);
  oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
  const oneMonthAgo = new Date(currentDate);
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(currentDate.getDate() - 7);
  const handleFilter = (event) => {
    setFiltered(event);
    if (event === "Today") {
      setpage(1);
      setFrom(currentDate?.toISOString()?.split("T")[0]);
      setTo(currentDate?.toISOString()?.split("T")[0]);
    } else if (event === "Week") {
      setpage(1);
      setFrom(oneWeekAgo?.toISOString()?.split("T")[0]);
      setTo(currentDate?.toISOString()?.split("T")[0]);
    } else if (event === "Month") {
      setpage(1);
      setFrom(oneMonthAgo?.toISOString()?.split("T")[0]);
      setTo(currentDate?.toISOString()?.split("T")[0]);
    } else if (event === "Year") {
      setpage(1);
      setFrom(oneYearAgo?.toISOString()?.split("T")[0]);
      setTo(currentDate?.toISOString()?.split("T")[0]);
    } else {
    }
  };
  const HandleFromdate = (event) => {
    setFrom(event.target.value);
    setpage(1);
  };
  const HandleTodate = (event) => {
    setTo(event.target.value);
    setpage(1);
  };
  const FetchOrganizations = async () => {
    const response = await fetch(url.getOrganizations(page, from, to), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsondata = await response.json();
      setData(jsondata?.data);
    }
  };
  useEffect(() => {
    FetchOrganizations();
  }, [page, from, to]);
  const filter = [
    {
      value: "Today",
      label: "Today",
    },
    { value: "Week", label: "Week" },
    {
      value: "Month",
      label: "Month",
    },
    { value: "Year", label: "Year" },
  ];
  const customStyles = {
    rows: {
      style: {
        color: "#000000",
      },
    },

    headCells: {
      style: {
        backgroundColor: "#fff",
        borderColor: "#4ba5fa",
        color: "black",
        fontSize: 14,
        fontWeight: "600",
      },
    },
    cells: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "0.5px",
        borderTopColor: "#4ba5fa",
      },
    },
    pagination: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "0.5px",
        borderTopColor: "#4ba5fa",
      },
    },
  };
  const columns = [
    {
      name: "S.No",
      selector: (row) => row.sno,
      width: "64px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "180px",
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
    },
    {
      name: "Address",
      selector: (row) => row.address,
    },
    {
      name: "Admin Name",
      selector: (row) => row.admin_name,
    },
    {
      name: "Created at",
      selector: (row) => row.created,
    },
  ];
  const data =
    organization?.org_data?.length > 0
      ? organization?.org_data?.map((item, index) => {
          const name = `${item?.organization?.name}`.toUpperCase();
          const phone = item?.organization?.telecom[0]?.value;
          const address = `${item?.organization?.address[0]?.text},${item?.organization?.address[0]?.city},${item?.organization?.address[0]?.state},${item?.organization?.address[0]?.country},${item?.organization?.address[0]?.postalCode}.`;
          const admin_name = item?.organization?.contact[0]?.name[0]?.text;
          const created = item?.created_at?.split("T")[0];
          return {
            sno: parseInt((page - 1) * 10) + index + 1,
            name,
            phone,
            address,
            admin_name,
            created,
          };
        })
      : [];
  return (
    <div className="mainHead">
      <Header />
      <div className="dashContainer">
        <div className="contain">
          <p id="head">Organizations</p>
          <div className="top">
            <Button
              style={{ width: 200, fontWeight: 600 }}
              text={` Count : ${organization?.count}`}
            />
            <div className="second">
              <p id="subtext">Filter by</p>
              <div className="third">
                <CalendarData value={from} setvalue={HandleFromdate} />
                <CalendarData value={to} setvalue={HandleTodate} />
              </div>
              <div className="radio">
                {filter?.map((item) => (
                  <Radio
                    key={item}
                    value={filtered ? filtered : "Today"}
                    label={item?.label}
                    setvalue={() => handleFilter(item?.value)}
                    checked={filtered === item?.value}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="table">
            <DataTable
              columns={columns}
              data={data}
              // pagination
              customStyles={customStyles}
            />
          </div>
          <div className="bottomNav">
            {page > 1 && (
              <Button
                style={{ width: 72, padding: 4, fontSize: 14 }}
                left={true}
                text={"Prev"}
                onClick={() => {
                  setpage(page - 1);
                  setCount(count - 15);
                }}
              ></Button>
            )}
            {data?.length >= 10 && (
              <Button
                style={{ width: 72, padding: 4, fontSize: 14 }}
                text={"Next"}
                onClick={() => {
                  setpage(page + parseInt(1));
                  setCount(count + 15);
                }}
                right={true}
              ></Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
