import React, { useEffect, useState } from "react";
import Header from "../component/header";
import Card from "../component/card";
import "../css/main.css";
import Button from "../component/button";
import CalendarData from "../component/calendar";
import Radio from "../component/radio";
import DataTable from "react-data-table-component";
import {
  RetriveAccessToken,
  RetriveToken,
  filterAppointment,
  filterdatabyDate,
  isSmallMobile,
  ismobile,
} from "../../utility/const";
import { url } from "../../utility/apis";
import { DropDown } from "../component/dropdown";
import { doc } from "firebase/firestore/lite";

const Appointment = () => {
  const token = RetriveAccessToken("access");
  const [org_options, setOrg_options] = useState([]);
  const [org_data, setOrgData] = useState({});
  const [doc_options, setdoc_options] = useState([]);
  const [doc_data, setDoc_data] = useState({});
  const [pat_options, setpat_options] = useState([]);
  const [pat_data, setpat_data] = useState({});
  const [appdata, setData] = useState();
  const [page, setpage] = useState(1);
  const [count, setCount] = useState(1);
  const [filtered, setFiltered] = useState("Today");
  const [from, setFrom] = useState(new Date()?.toISOString()?.split("T")[0]);
  const [to, setTo] = useState(new Date()?.toISOString()?.split("T")[0]);
  const currentDate = new Date();
  const oneYearAgo = new Date(currentDate);
  oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
  const oneMonthAgo = new Date(currentDate);
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(currentDate.getDate() - 7);
  const handleFilter = (event) => {
    setFiltered(event);

    if (event === "Today") {
      //  setpage(1)
      setFrom(currentDate?.toISOString()?.split("T")[0]);
      setTo(currentDate?.toISOString()?.split("T")[0]);
    } else if (event === "Week") {
      setpage(1);
      setFrom(oneWeekAgo?.toISOString()?.split("T")[0]);
      setTo(currentDate?.toISOString()?.split("T")[0]);
    } else if (event === "Month") {
      setpage(1);
      setFrom(oneMonthAgo?.toISOString()?.split("T")[0]);
      setTo(currentDate?.toISOString()?.split("T")[0]);
    } else if (event === "Year") {
      setpage(1);
      setFrom(oneYearAgo?.toISOString()?.split("T")[0]);
      setTo(currentDate?.toISOString()?.split("T")[0]);
    } else {
    }
  };

  const HandleFromdate = (event) => {
    setFrom(event.target.value);
    setpage(1);
    //  setFiltered(event.target.value == "")
  };
  const HandleTodate = (event) => {
    setTo(event.target.value);
    setpage(1);
    // setFiltered('')
  };
  const FetchAppointment = async () => {
    const response = await fetch(
      url.getappointment(
        page,
        from,
        to,
        doc_data?.profile_id ? doc_data?.profile_id : "",
        org_data?.profile_id ? org_data?.profile_id : "",
        pat_data?.profile_id ? pat_data?.profile_id : ""
      ),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const jsondata = await response.json();
    if (response.ok) {
      setData(jsondata?.data);
    }
  };
  useEffect(() => {
    FetchAppointment();
  }, [page, from, to, org_data, pat_data, doc_data]);
  const Fetch_Org_Pat_Metadata = async () => {
    const response = await fetch(url?.getOrganizationMetadata, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsondata = await response.json();
      const newdata = jsondata?.data?.map((item) => ({
        name: item?.organization?.name,
        profile_id: item?.profile_id,
      }));
      setOrg_options(newdata);
    }
    const pat_response = await fetch(url.getPatientsMetadata, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const patjsondata = await pat_response.json();
      const newdata = patjsondata?.data?.map((item) => ({
        name: `${item?.patient?.name[0]?.prefix?.join("")} ${
          item?.patient?.name[0]?.text
        }`,
        profile_id: item?._id,
      }));
      setpat_options(newdata);
    }
  };
  useEffect(() => {
    Fetch_Org_Pat_Metadata();
  }, []);
  const FetchDoctorMetaData = async () => {
    const response = await fetch(
      url.getDoctorsMetadata(org_data?.profile_id ? org_data?.profile_id : ""),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.ok) {
      const jsondata = await response.json();
      const newdata = jsondata?.data
        ? jsondata?.data?.map((item) => ({
            name:
              item?.profile?.name?.text?.startsWith("dr") ||
              item?.profile?.name?.text?.startsWith("dr.")
                ? item?.profile?.name?.text?.toUpperCase()
                : `Dr. ${item?.profile?.name?.text?.toUpperCase()}`,
            profile_id: item?.profile?.profile_id,
          }))
        : [];
      setdoc_options(newdata);
    }
  };
  useEffect(() => {
    if (JSON.stringify(org_data) !== "{}") {
      FetchDoctorMetaData();
    }
  }, [org_data]);
  const filter = [
    {
      value: "Today",
      label: "Today",
    },
    { value: "Week", label: "Week" },
    {
      value: "Month",
      label: "Month",
    },
    { value: "Year", label: "Year" },
  ];
  const customStyles = {
    rows: {
      style: {
        color: "#000000",
      },
    },

    headCells: {
      style: {
        backgroundColor: "#fff",
        borderColor: "#4ba5fa",
        color: "black",
        fontSize: 14,
        fontWeight: "600",
      },
    },
    cells: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "0.5px",
        borderTopColor: "#4ba5fa",
      },
    },
    pagination: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "0.5px",
        borderTopColor: "#4ba5fa",
      },
    },
  };
  const columns = [
    {
      name: "S.No",
      selector: (row) => row.sno,
      width: "64px",
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      width: "100px",
    },
    {
      name: "Time",
      selector: (row) => row.time,
    },
    {
      name: "Patient Name",
      selector: (row) => row.patientname,
      width: "180px",
    },
    {
      name: "Clinic name",
      selector: (row) => row.cname,
      width: "180px",
    },
    {
      name: "Dr Name",
      selector: (row) => row.drname,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
  ];
  const data = appdata?.appointment_data?.map((item, index) => {
    const data = item?.appointment;
    const date = data?.start?.split("T")[0];
    const type =
      data?.appointmentType?.text === "WALKIN"
        ? "NEW"
        : data?.appointmentType?.text;
    const time = item?.slot;
    const patientname = data?.participant?.[1]?.actor?.display;
    const cname = data?.supportingInformation?.[0]?.display;
    const drname = data?.participant?.[0]?.actor?.display;
    const status = data?.status === "pending" ? "Pending" : "Completed";
    return {
      sno: parseInt((page - 1) * 10) + index + 1,
      date,
      type,
      time,
      patientname,
      cname,
      drname,
      status,
    };
  });
  return (
    <div className="mainHead">
      <Header />
      <div className="dashContainer">
        <div className="contain">
          <p id="head">Appointments</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 4,
              marginBottom: 12,
            }}
          >
            <DropDown
              label={"Organization"}
              value={org_data}
              setValue={setOrgData}
              options={org_options}
              pr_name={"name"}
            />
            {JSON.stringify(org_data) !== "{}" && (
              <DropDown
                label={"Doctors"}
                value={doc_data}
                setValue={setDoc_data}
                options={doc_options?.length > 0 ? doc_options : []}
                pr_name={"name"}
              />
            )}
            <DropDown
              label={"Patients"}
              value={pat_data}
              setValue={setpat_data}
              options={pat_options}
              pr_name={"name"}
            />
          </div>
          <div className="top">
            <Button
              style={{ width: 160, fontWeight: 600 }}
              text={`Count : ${appdata?.count}`}
            />
            <div className="second">
              <p id="subtext">Filter by</p>
              <div className="third">
                <CalendarData
                  default={"From"}
                  value={from}
                  setvalue={HandleFromdate}
                />
                <CalendarData value={to} setvalue={HandleTodate} />
              </div>
              <div className="radio">
                {filter?.map((item) => (
                  <Radio
                    key={item}
                    value={filtered ? filtered : "Today"}
                    label={item?.label}
                    setvalue={() => handleFilter(item?.value)}
                    checked={filtered === item?.value}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="table">
            <DataTable
              columns={columns}
              data={data}
              // pagination
              customStyles={customStyles}
            />
          </div>
          <div className="bottomNav">
            {page > 1 && (
              <Button
                style={{ width: 72, padding: 4, fontSize: 14 }}
                left={true}
                text={"Prev"}
                onClick={() => {
                  setpage(page - 1);
                  setCount(count - 15);
                }}
              ></Button>
            )}
            {data?.length >= 10 && (
              <Button
                style={{ width: 72, padding: 4, fontSize: 14 }}
                text={"Next"}
                onClick={() => {
                  setpage(page + parseInt(1));
                  setCount(count + 15);
                }}
                right={true}
              ></Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Appointment;

// import React, { useState,useEffect } from "react";
// import Header from "../component/header";
// import Card from "../component/card";
// import "../css/main.css";
// import Button from "../component/button";
// import CalendarData from "../component/calendar";
// import Radio from "../component/radio";
// import DataTable from 'react-data-table-component';
// import { RetriveToken, filterdatabyDate } from "../../utility/const";
// import { url } from "../../utility/apis";
// const Appointments = () =>
// {
//   const token = RetriveToken("token");
//   const [appdata,setData] = useState()
//   const [page,setpage] = useState(1)
//   const [filtered,setFiltered] = useState('Today')
//   const [from,setFrom] = useState('')
//   const [to,setTo] = useState('')
//   const currentDate = new Date();
//   const oneYearAgo = new Date(currentDate);
//   oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
//   const oneMonthAgo = new Date(currentDate);
//   oneMonthAgo.setMonth(currentDate.getMonth() - 1);
//   const oneWeekAgo = new Date(currentDate);
//   oneWeekAgo.setDate(currentDate.getDate() - 7);
//   const handleFilter =(event)=>{
//     setFiltered(event)
//     if(event === "Today" && filtered ==="Today"){
//      setpage(1)
//      setFrom(currentDate?.toISOString()?.split('T')[0])
//      setTo(currentDate?.toISOString()?.split('T')[0])
//     }
//     else if(event === "Week"){
//       setpage(1)
//      setFrom(oneWeekAgo?.toISOString()?.split('T')[0])
//      setTo(currentDate?.toISOString()?.split('T')[0])
//     }
//     else if(event === "Month"){
//       setpage(1)
//      setFrom(oneMonthAgo?.toISOString()?.split('T')[0])
//      setTo(currentDate?.toISOString()?.split('T')[0])
//     }
//     else if(event === "Year"){
//       setpage(1)
//      setFrom(oneYearAgo?.toISOString()?.split('T')[0])
//      setTo(currentDate?.toISOString()?.split('T')[0])
//     }
//     else{

//     }
//   }
//   const HandleFromdate =(event)=>{
//    setFrom(event.target.value)
//    setpage(1)
//   //  setFiltered(event.target.value == "")
//   }
//   const HandleTodate =(event)=>{
//     setTo(event.target.value)
//     setpage(1)
//     // setFiltered('')
//   }
//  console.log(appdata);
//   const FetchAppointment = async (page)=>{
//     const response = await fetch(url.getappointment(page),{
//       method:'GET',
//       headers:{
//         Authorization:`Bearer ${token.access_token}`,
//       },
//     });
//     if(response.ok){
//       const jsondata = await response.json();
//       setData(jsondata?.data)
//     }
//   }
//   useEffect(()=>{
//     FetchAppointment(page);
//   },[page])

//   const filter =[
//     {
//       value:"Today",
//       label:"Today"
//     },
//     {      value:"Week",
//       label:"Week"
//     },
//     {
//       value:"Month",
//       label:"Month"
//     },
//     {      value:"Year",
//       label:"Year"
//     }
//   ]

//   const customStyles = {
//     rows: {
//       style: {
//         color:"#000000"
//       },
//     },

//     headCells: {
//       style: {
//         backgroundColor:'#fff',
//         borderColor: "#4ba5fa",
//         color: "black",
//         fontSize:14,
//         fontWeight:"600"
//       },
//     },
//     cells: {
//       style: {
//         borderTopStyle: 'solid',
//         borderTopWidth: '0.5px',
//         borderTopColor: "#4ba5fa",
//       },
//     },
//     pagination: {
//       style: {
//         borderTopStyle: 'solid',
//         borderTopWidth: '0.5px',
//         borderTopColor: "#4ba5fa",
//       },
//     },
//   };
//   const columns = [
//     {
//       name: 'S.No',
//       selector: row => row.sno,
//     },
//     {
//       name: 'Date',
//       selector: row => row.date,
//     },
//     {
//       name: 'Type',
//       selector: row => row.type,
//     },
//     {
//       name: 'Time',
//       selector: row => row.time,
//     },
//     {
//       name: 'Patient Name',
//       selector: row => row.patientname,
//     },
//     {
//       name: 'Patient Ph',
//       selector: row => row.patientph,
//     },
//     {
//       name: 'Clinic name',
//       selector: row => row.cname,
//     },
//     {
//       name: 'Dr Name',
//       selector: row => row.drname,
//     },
//     {
//       name: 'Dr ph',
//       selector: row => row.drph,
//     },
//     {
//       name: 'Status',
//       selector: row => row.Status,
//     },
//   ];

//   const data = appdata?.data?.map((item,index) => ({
//     sno: index+1,
//     date: item?.appointment_date,
//     type: item?.appointment_type,
//     time: item?.appointment_slot,
//     patientname: item?.patient_data?.patient_name,
//     patientph: item?.patient_data?.patient_phone_number,
//     cname: item?.clinic_data?.clinic_name,
//     drname: item?.doctor_data?.doctor_name,
//     drph: item?.doctor_data?.doctor_phone_number,
//     Status: item?.status,
//   }));
//   const newdata = filterdatabyDate(data,from,to)
//   console.log('newdata',newdata);
//   // useEffect(() => {
//   //   // if (!filtered) {
//   //   //   setFiltered("Today");
//   //   // }
//   //   handleFilter(filtered);
//   // }, [filtered]);
//   return (
//     <div className="dashContainer">
//       <Header />
//       <div className="contain">
//         <p id="head">Appointments</p>
//         <div className="top">
//           <Button text={`Total Count : ${appdata?.data.length!==undefined ? appdata?.data?.length : 0}`} />
//           <div className="second">
//             <p id="subtext">Filter by</p>
//             <div className="third">
//               <CalendarData value={from} setvalue={HandleFromdate}/>
//               <CalendarData value={to} setvalue={HandleTodate}/>
//             </div>
//            <div className="radio">
//             {filter?.map((item)=>(
//               <Radio value={filtered ? filtered : "Today"} label={item?.label} setvalue={() => handleFilter(item?.value)} checked={filtered === item?.value} />
//             ))}
//            </div>
//           </div>
//         </div>
//        <div className="table">
//        <DataTable
// 			columns={columns}
// 			data={data}
//       // pagination
//       customStyles={customStyles}
// 		/>
//         </div>
//         {appdata?.next!==null && <button onClick={()=>setpage(page+1)}>Next</button>}
//         {page>1 && <button onClick={()=>setpage(page-1)}>Prev</button>}
//       </div>
//     </div>
//   );
// };
// export default Appointments;
