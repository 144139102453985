import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: ""
}


const TokenSlice = createSlice({
    name: "token", initialState, reducers: {
        addToken: (state, action) => {
            state.token = action.payload
        }
    }
})

export const { addToken } = TokenSlice.actions
export const TokenReducer = TokenSlice.reducer