import React from "react";
import "../css/component.css";
import { FaArrowRight } from "react-icons/fa6";
const Card = (props) => {
  return (
    <div onClick={props.onClick} className="cardcontainer">
      <div className="subcontain">
        <p id="sub">{props.title}</p>
        <div className="total">
          <p id="number">{props.total}</p>
        </div>
      </div>
      <div className="arrow">
        <FaArrowRight color="#4BA5FA" />
      </div>
    </div>
  );
};
export default Card;
