import React, { useEffect, useState } from "react";
import Header from "../component/header";
import "../css/main.css";
import Button from "../component/button";
import CalendarData from "../component/calendar";
import Radio from "../component/radio";
import DataTable from "react-data-table-component";
import {
    RetriveAccessToken,
    RetriveToken,
    filterdatabyDate,
} from "../../utility/const";
import { url } from "../../utility/apis";
import { DropDown } from "../component/dropdown";

const Reviews = () => {
    const token = RetriveAccessToken("access");
    const [docdata, setData] = useState();
    const [count, setCount] = useState(1);
    const [page, setpage] = useState(1);
    // const [orgdata, setOrgData] = useState({});
    // const [option, setOptions] = useState([]);
    // const [filtered, setFiltered] = useState("Today");
    // const [from, setFrom] = useState(new Date()?.toISOString()?.split("T")[0]);
    // const [to, setTo] = useState(new Date()?.toISOString()?.split("T")[0]);
    // const currentDate = new Date();
    // const oneYearAgo = new Date(currentDate);
    // oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
    // const oneMonthAgo = new Date(currentDate);
    // oneMonthAgo.setMonth(currentDate.getMonth() - 1);
    // const oneWeekAgo = new Date(currentDate);
    // oneWeekAgo.setDate(currentDate.getDate() - 7);
    // const handleFilter = (event) => {
    //     setFiltered(event);
    //     if (event === "Today") {
    //         //  setpage(1)
    //         setFrom(currentDate?.toISOString()?.split("T")[0]);
    //         setTo(currentDate?.toISOString()?.split("T")[0]);
    //     } else if (event === "Week") {
    //         //   setpage(1)
    //         setFrom(oneWeekAgo?.toISOString()?.split("T")[0]);
    //         setTo(currentDate?.toISOString()?.split("T")[0]);
    //     } else if (event === "Month") {
    //         //   setpage(1)
    //         setFrom(oneMonthAgo?.toISOString()?.split("T")[0]);
    //         setTo(currentDate?.toISOString()?.split("T")[0]);
    //     } else if (event === "Year") {
    //         //   setpage(1)
    //         setFrom(oneYearAgo?.toISOString()?.split("T")[0]);
    //         setTo(currentDate?.toISOString()?.split("T")[0]);
    //     } else {
    //     }
    //     setpage(1);
    // };

    // const HandleFromdate = (event) => {
    //     setFrom(event.target.value);
    //     setpage(1);
    //     //  setFiltered(event.target.value == "")
    // };
    // const HandleTodate = (event) => {
    //     setTo(event.target.value);
    //     setpage(1);
    //     // setFiltered('')
    // };
    const getReviews = async () => {
        const response = await fetch(url.getReviews(page), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        const jsonData = await response.json()
        setData(jsonData?.data?.length > 0 ? jsonData?.data : [])
    }
    useEffect(() => {
        getReviews()
    }, [page])
    // const filter = [
    //     {
    //         value: "Today",
    //         label: "Today",
    //     },
    //     { value: "Week", label: "Week" },
    //     {
    //         value: "Month",
    //         label: "Month",
    //     },
    //     { value: "Year", label: "Year" },
    // ];
    const customStyles = {
        rows: {
            style: {
                color: "#000000",
            },
        },

        headCells: {
            style: {
                backgroundColor: "#fff",
                borderColor: "#4ba5fa",
                color: "black",
                fontSize: 14,
                fontWeight: "600",
            },
        },
        cells: {
            style: {
                borderTopStyle: "solid",
                borderTopWidth: "0.5px",
                borderTopColor: "#4ba5fa",
            },
        },
        pagination: {
            style: {
                borderTopStyle: "solid",
                borderTopWidth: "0.5px",
                borderTopColor: "#4ba5fa",
            },
        },
    };
    const columns = [
        {
            name: "S.No",
            selector: (row) => row.sno,
            width: "64px",
        },
        {
            name: "Name",
            selector: (row) => row.name,
        },
        {
            name: "Email",
            selector: (row) => row.email,
        },
        {
            name: "Message",
            selector: (row) => row.message,
            width: "84px",
        },
        {
            name: "Send at",
            selector: (row) => row.created,
        },
    ];
    const data = docdata?.map((item, index) => {
        return {
            sno: count + index,
            name: item?.name,
            email: item?.email,
            message: item?.message,
            created: item?.created_at?.split("T")[0],
        };
    });
    return (
        <div className="mainHead">
            <Header />
            <div className="dashContainer">
                <div className="contain">
                    <p id="head">Reviews</p>
                    {/* <div className="top">

                        <div className="second">
                            <p id="subtext">Filter by</p>
                            <div className="third">
                                <CalendarData value={from} setvalue={HandleFromdate} />
                                <CalendarData value={to} setvalue={HandleTodate} />
                            </div>
                            <div className="radio">
                                {filter?.map((item, index) => (
                                    <Radio
                                        key={index}
                                        value={filtered ? filtered : "Today"}
                                        label={item?.label}
                                        setvalue={() => handleFilter(item?.value)}
                                        checked={filtered === item?.value}
                                    />
                                ))}
                            </div>
                        </div>
                    </div> */}
                    <div className="table">
                        <DataTable
                            columns={columns}
                            data={data}
                            // pagination
                            customStyles={customStyles}
                        />
                    </div>
                    <div className="bottomNav">
                        {page > 1 && (
                            <Button
                                style={{ width: 72, padding: 4, fontSize: 14 }}
                                left={true}
                                text={"Prev"}
                                onClick={() => {
                                    setpage(page - 1);
                                    setCount(count - 15);
                                }}
                            ></Button>
                        )}
                        {docdata?.next !== null && (
                            <Button
                                style={{ width: 72, padding: 4, fontSize: 14 }}
                                text={"Next"}
                                onClick={() => {
                                    setpage(page + parseInt(1));
                                    setCount(count + 15);
                                }}
                                right={true}
                            ></Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Reviews;
