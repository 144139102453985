import React from "react";
import "../css/component.css"
const Radio =(props)=>{
    return(
        <div>
        <label class="custom-radio">
        <span class="radio-label">{props.label}</span>
        <input type="radio" name="options" value={props.value} onChange={props.setvalue} checked={props.checked}/>
        <span class="radio-check"></span>
        </label>
        </div>
    )
};
export default Radio;
